import React, { useState, useRef, useEffect } from "react";
import PlusIcon from "../../assets/svg/plus-icon.svg";
import MinusIcon from "../../assets/svg/minus-icon.svg";
import "./Accordion.scss";

import useWindowSize from "../../hooks/useWindowResize";

const AccordionItem = ({ question, answer }) => {
  const { width } = useWindowSize();
  const isMobile = width <= 480;

  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      // Ensure that the maxHeight is set correctly based on the content
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
    }
  }, [isOpen]);

  return (
    <div className={`accordion-item ${isOpen ? "open" : ""}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <p>{question}</p>
        <img src={isOpen ? MinusIcon : PlusIcon} alt="toggle icon" />
      </div>
      <div
        className="accordion-content"
        ref={contentRef}
        {...(isMobile && { onClick: toggleAccordion })}
      >
        <div className="accordion-inner">
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ faqs }) => {
  return (
    <div className="accordion">
      {faqs.map((faq, index) => (
        <AccordionItem
          key={index}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
};

export default Accordion;
