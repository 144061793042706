import React from "react";
import "./Button.scss";

const Button = ({
  className,
  children,
  icon: Icon,
  onClick,
  active,
  disabled,
  ...rest
}) => {
  return (
    <div
      className={`button ${className} ${active ? "active" : ""} ${
        disabled ? "disable" : ""
      }`}
      onClick={!disabled ? onClick : undefined}
      {...rest}
    >
      {Icon && <Icon className="button-icon" />}
      {children}
    </div>
  );
};

Button.defaultProps = {
  className: "", // Default has no custom class
  icon: null, // Default has no icon
  onClick: () => {}, // Default no-op function
  active: false, // Default inactive
  disabled: false, // Default not disabled
};

export default Button;
