import React from "react";
import "./Modal.scss";

import Button from "../Button/Button";

const Modal = ({ isVisible, onAgree, onDisagree }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-background">
      <div className="modal-container">
        <p className="modal-title">Non-Disclosure Notice</p>
        <p className="modal-desc">
          This website is confidential and intended solely for the recipient(s).
          It contains proprietary information belonging to TorumPay (Torum
          International Sdn Bhd 202201042743 [1488440-A]).
        </p>

        <p className="modal-desc">
          The information presented is provided exclusively for discussion
          purposes and should not be shared, distributed, or reproduced without
          the explicit written consent of TorumPay.
        </p>

        <p className="modal-desc bold">
          By viewing this site, you agree to maintain the confidentiality of the
          information and refrain from disclosing it to any third party.
        </p>

        <div className="modal-button-wrapper">
          <Button className="modal-button agree" onClick={onAgree}>
            Yes, I agree
          </Button>
          <Button className="modal-button disagree" onClick={onDisagree}>
            No, I disagree
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
