import React, { useState, useRef } from "react";

import providersData from "./providersData";

import useWindowSize from "../../../hooks/useWindowResize";
import PlusIcon from "../../../assets/svg/plus-icon.svg";
import MinusIcon from "../../../assets/svg/minus-icon.svg";

import "../../../styles/global.scss";
import "../../../styles/mixins.scss";
import "../../../styles/variables.scss";
import "./CompareTheDifference.scss";

const CompareTheDifference = () => {
  const { width } = useWindowSize();
  const Providers = providersData(width);

  // Ensure the first index is expanded initially
  const [isOpen, setIsOpen] = useState([0]);
  const panelRefs = useRef({});

  const toggleAccordion = (clickedIndex) => {
    setIsOpen(
      (prevOpen) =>
        prevOpen.includes(clickedIndex)
          ? prevOpen.filter((index) => index !== clickedIndex) // Close if already open
          : [...prevOpen, clickedIndex] // Open if not already open
    );
  };

  return (
    <div className="tpcard-cpdifference-wrapper max-width">
      <div>
        <p className="section-title Fu-90s-90l">COMPARE THE DIFFERENCE</p>
        <p className="section-desc Ft-20s-26l">
          Most crypto payment providers in Malaysia are non-regulated locally,
          expensive, and add hidden fees. TorumPay is different—fully regulated
          by SC Malaysia, transparent, and cost-effective. See for yourself and
          experience fair pricing with no hidden charges.
        </p>
      </div>

      {width > 1024 ? (
        <div className="tpcard-cpdifference-chart-container">
          {Providers.map((panel, index) => {
            const slotClass = [
              "slot Ft-20s-28l",
              index === 0 ? "first-column Ft-18s-18l" : "",
              index === 1 ? "fw600" : "",
            ]
              .filter(Boolean)
              .join(" ");

            const headerRow =
              index === 1 || index === 2 || index === 3
                ? "underlined header-row"
                : "header-row";

            return (
              <div
                className="tpcard-cpdifference-chart-panel Ft-20s-28l"
                key={index}
              >
                <div className={`${slotClass} ${headerRow}`}>
                  {panel.logo}
                  {panel.name}
                </div>
                <div className={slotClass}>
                  {panel.countryLogo}
                  {panel.countryName}
                </div>
                <div className={slotClass}>{panel.status}</div>
                <div className={slotClass}>{panel.deposit}</div>
                <div className={slotClass}>{panel.subFee}</div>
                <div className={`${slotClass} slot-row Ft-20s-28l`}>
                  {panel.fee}
                </div>
                <div className={slotClass}>{panel.withdrawal}</div>
                <div className={slotClass}>
                  {panel.paymentLogo}
                  {panel.paymentName}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="tp-card-mobilechart-container">
          {Providers.slice(1).map((panel, index) => (
            <div key={index} className="tp-card-mobilechart-wrapper">
              <div
                onClick={() => toggleAccordion(index)}
                className="panel-header"
              >
                <div className="panel-header-left fw500 Ft-18s-18l">
                  {panel.logo}
                  {panel.name}
                </div>
                <img
                  src={isOpen.includes(index) ? MinusIcon : PlusIcon}
                  alt="toggle icon"
                />
              </div>
              {/* Smooth Expanding Panel */}
              <div
                ref={(el) => (panelRefs.current[index] = el)}
                className="detail-panel-container"
                style={{
                  maxHeight: isOpen.includes(index)
                    ? `${panelRefs.current[index]?.scrollHeight}px`
                    : "0px",
                  opacity: isOpen.includes(index) ? "1" : "0",
                  transition:
                    "max-height 0.4s ease-in-out, opacity 0.1s ease-in-out",
                }}
              >
                <div className="detail-panel">
                  <p className="label Ft-16s-24l">{Providers[0].countryName}</p>
                  <div className="data Ft-18s-24l fw600">
                    {panel.countryLogo}
                    {panel.countryName}
                  </div>
                </div>

                <div className="detail-panel">
                  <p className="label Ft-16s-24l">{Providers[0].status}</p>
                  <div className="data Ft-18s-24l fw600">{panel.status}</div>
                </div>

                <div className="detail-panel">
                  <p className="label Ft-16s-24l">{Providers[0].deposit}</p>
                  <div className="data Ft-18s-24l fw600">{panel.deposit}</div>
                </div>

                <div className="detail-panel">
                  <p className="label Ft-16s-24l">{Providers[0].subFee}</p>
                  <div className="data Ft-18s-24l fw600">{panel.subFee}</div>
                </div>

                <div className="detail-panel">
                  <p className="label Ft-16s-24l">{Providers[0].fee}</p>
                  <div className="data Ft-18s-24l fw600">{panel.fee}</div>
                </div>

                <div className="detail-panel">
                  <p className="label Ft-16s-24l">{Providers[0].withdrawal}</p>
                  <div className="data Ft-18s-24l fw600">
                    {panel.withdrawal}
                  </div>
                </div>

                <div className="detail-panel">
                  <p className="label Ft-16s-24l">{Providers[0].paymentName}</p>
                  <div className="data Ft-18s-24l fw600">
                    {panel.paymentLogo}
                    {panel.paymentName}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompareTheDifference;
