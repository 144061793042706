import TorumPayIcon from "../../../assets/webp/cp-torumpay-icon.webp";
import RedotPayIcon from "../../../assets/webp/cp-redotpay-icon.webp";
import BybitCardIcon from "../../../assets/webp/cp-bybit-icon.webp";
import MalaysiaIcon from "../../../assets/webp/malaysia-icon.webp";
import HongKongIcon from "../../../assets/webp/hongkong-icon.webp";
import EuropeanIcon from "../../../assets/webp/europe-icon.webp";
import DuitNowQrIcon from "../../../assets/webp/duitnow-icon.webp";
import CrossIcon from "../../../assets/webp/cross-icon.webp";

import useWindowSize from "../../../hooks/useWindowResize";

import "../../../styles/global.scss";
import "../../../styles/mixins.scss";
import "../../../styles/variables.scss";
import "./CompareTheDifference.scss";

const providersData = (width) => [
  {
    logo: "",
    name: "",
    countryLogo: "",
    countryName: "Card Issuance",
    status: (
      <>
        Regulatory Status{" "}
        {width > 1024 ? (
          <span className="gray"> in Malaysia</span>
        ) : (
          "in Malaysia"
        )}
      </>
    ),
    deposit: "Deposit Requirement",
    subFee: (
      <>
        Subscription Fee / {width > 1024 && <br />}
        Joining Fee
      </>
    ),
    fee: "Transaction Fee",
    withdrawal: "ATM Withdrawal Fee",
    paymentLogo: "",
    paymentName: "Local QR Payment",
  },
  {
    logo: <img src={TorumPayIcon} />,
    name: "TorumPay",
    countryLogo: <img src={MalaysiaIcon} />,
    countryName: "Malaysia",
    status: "First & Fully Regulated by SC Malaysia",
    deposit: "No",
    subFee: "RM14.99 /month or Stake 1 ETH / 10 SOL",
    fee: (
      <>
        <div className="slot-row">
          Fixed 1% {width > 1024 && <br />} Crypto-to-Ringgit Fee
          <span className="red Ft-14s-14l">NO HIDDEN CHARGES</span>
        </div>
      </>
    ),
    withdrawal: "Fixed RM2.00",
    paymentLogo: <img src={DuitNowQrIcon} />,
    paymentName: "DuitNow QR",
  },
  {
    logo: <img src={RedotPayIcon} />,
    name: "RedotPay",
    countryLogo: <img src={HongKongIcon} />,
    countryName: "Hong Kong",
    status: <span className="red fw600">Non-regulated</span>,
    deposit: "No",
    subFee: (
      <>
        100 USD {width > 1024 && <br />}
        one-time fee
      </>
    ),
    fee: (
      <>
        <div className="slot-row">
          <span className="Ft-20s-28l"> ~2.2%</span>
          <span className="red Ft-14s-14l fw500">1.0% CRYPTO-TO-FIAT FEE</span>
          <span className="red Ft-14s-14l fw500">1.2% FX RATE MARKUP</span>
        </div>
      </>
    ),
    withdrawal: "2%",
    paymentLogo: <img src={CrossIcon} />,
  },
  {
    logo: <img src={BybitCardIcon} />,
    name: "Bybit Card",
    countryLogo: <img src={EuropeanIcon} />,
    countryName: "Europe",
    status: <span className="red fw600">Non-regulated</span>,
    deposit: <span className="red fw600">100,000 USD</span>,
    subFee: (
      <>
        5 USD
        {width > 1024 && <br />} one-time fee
      </>
    ),
    fee: (
      <>
        <div className="slot-row">
          <span className="Ft-20s-28l"> ~2%</span>
          <span className="red Ft-14s-14l fw500">1.0% TRANSACTION FEE</span>
          <span className="red Ft-14s-14l fw500">0.9% FX RATE MARKUP</span>
        </div>
      </>
    ),
    withdrawal: "2%",
    paymentLogo: <img src={CrossIcon} />,
  },
];

export default providersData;
