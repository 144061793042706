import React from "react";
import { useNavigate } from "react-router-dom";

import SpotlightImg1 from "../../../assets/png/card-showcase-img-1.png";
import SpotlightImg2 from "../../../assets/png/card-showcase-img-2.png";
import SpotlightImg3 from "../../../assets/png/card-showcase-img-3.png";
import SpotlightImg4 from "../../../assets/png/card-showcase-img-4.png";
import SpotlightImg5 from "../../../assets/png/card-showcase-img-5.png";
import SpotlightImg6 from "../../../assets/png/card-showcase-img-6.png";

import Button from "../../../components/Button/Button";

import "../../../styles/variables.scss";
import "./ProductSpotlight.scss";

function ProductSpotlight() {
  const navigateTo = useNavigate();

  const suitYourself = [
    {
      title: "SEIZE YOUR CRYPTO CARD",
      desc: "Own and activate your perfect crypto card, all in just minutes.",
      img: SpotlightImg1,
      link: "/card",
    },
    {
      title: "INSTANT CRYPTO TRADING",
      desc: "Buy and sell crypto with Ringgit in seconds, starting at just RM10.",
      img: SpotlightImg2,
    },
    {
      title: "YOUR PAYMENT, YOUR WAY",
      desc: "Contactless payments or DuitNow QR, the choice is yours.",
      img: SpotlightImg3,
    },
    {
      title: "NO HIDDEN FEES, NO SURPRISES",
      desc: "Enjoy transparent pricing with lowest rates and zero hidden fees.",
      img: SpotlightImg4,
    },
    {
      title: "STAKE, EARN AND SPEND",
      desc: "Put your crypto to work and turn them into spending money.",
      img: SpotlightImg5,
    },
    {
      title: "FULLY COMPLIANT AND REGULATED",
      desc: "Trade and pay with a peace of mind, experience uninterrupted and secure transactions.",
      img: SpotlightImg6,
      link: "https://www.sc.com.my/regulation/guidelines/recognizedmarkets/list-of-registered-digital-asset-exchanges",
    },
  ];

  return (
    <div className="tp-main-spotlight">
      <div className="tp-main-spotlight-wrapper">
        <div className="spotlight-header">
          <p className="spotlight-header-title Fu-90s-90l">Suit Yourself</p>
          <p className="spotlight-header-desc Ft-20s-26l">
            So much more than a digital wallet, we’ve handled everything you
            need from A-Z.
          </p>
        </div>

        <div className="tp-spotlight-grid">
          {suitYourself.map((item, index) => (
            <div
              key={index}
              className={`spotlight-item ${
                index === 0 || index === 5 ? "wide" : ""
              } bg-${index}`}
            >
              <div className="spotlight-text-wrapper">
                <p className="Ft-18s-18l fw600">{item.title}</p>
                <p className="Ft-32s-38l fw600">{item.desc}</p>
                {(index === 0 || index === 5) && (
                  <Button
                    className="hero-button suit-button"
                    onClick={() => {
                      if (index === 0) {
                        // Internal navigation
                        navigateTo(item.link);
                      } else if (index === 5) {
                        // External link with _blank
                        window.open(item.link, "_blank", "noopener,noreferrer");
                      }
                    }}
                  >
                    Learn more
                  </Button>
                )}
              </div>
              <div className="img-wrapper">
                <img src={item.img} alt={`spotlight-${index}`} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductSpotlight;
