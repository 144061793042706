import React from "react";

import "./ProductUsecase.scss";

import UsecaseImg1 from "../../../assets/png/usecase-img-1.png";
import UsecaseImg2 from "../../../assets/png/usecase-img-2.png";
import UsecaseImg3 from "../../../assets/png/usecase-img-3.png";

function ProductUsecase() {
  return (
    <div className="tp-main-usecase">
      <div className="tp-main-usecase-wrapper">
        <div className="usecase-header">
          <p className="usecase-header-title purple">Easy Transfers.</p>
          <p className="usecase-header-title-second">
            No more complex crypto wallet addresses
          </p>
          <p className="usecase-header-desc">
            Just like how you normally send money.
          </p>
        </div>

        <div className="usecase-category">
          <div className="usecase-content-wrapper">
            <img src={UsecaseImg1} alt="use-case-img-1" />
            <p>Swiftly send or receive cryptos among contacts.</p>
          </div>

          <div className="usecase-content-wrapper">
            <img src={UsecaseImg2} alt="use-case-img-2" />
            <p>QR code transfers at your fingertips.</p>
          </div>

          <div className="usecase-content-wrapper">
            <img src={UsecaseImg3} alt="use-case-img-3" />
            <p>Instant crypto transfers with just a tap.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductUsecase;
