import { useEffect, useState } from "react";

const useInView = (ref, threshold = 0.1) => {
  const [hasFadedIn, setHasFadedIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;

      const rect = ref.current.getBoundingClientRect();
      const isVisible =
        rect.top <= window.innerHeight * (1 - threshold) &&
        rect.bottom >= window.innerHeight * threshold;

      if (isVisible && !hasFadedIn) {
        setHasFadedIn(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref, threshold, hasFadedIn]);

  return hasFadedIn;
};

export default useInView;
