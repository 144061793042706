import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../Button/Button";
import TorumPayLogo from "../../assets/svg/torum-pay-logo.svg";
import { ReactComponent as NavIcon } from "../../assets/svg/nav-icon.svg";
import useWindowSize from "../../hooks/useWindowResize";

import "../../styles/variables.scss";
import "./AppHeader.scss";

const AppHeader = ({ scrollToSection }) => {
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = width <= 480;
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null); // Ref for detecting outside clicks

  const NavigationMobile = [
    { page: "Home", path: "/" },
    { page: "Card", path: "/card" },
    { page: "Referral", path: "/referral" },
  ];

  // Get current page title based on pathname
  const currentPage =
    NavigationMobile.find((nav) => nav.path === pathname)?.page || "Menu";

  // Detect Click Outside Nav Panel and Close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div className="tp-main-header">
      <div
        className={`tp-main-header-wrapper ${isMobile ? "mobile-header" : ""}`}
      >
        <img src={TorumPayLogo} alt="torum-pay-logo" />

        {width > 1024 ? (
          <div className="tp-main-header-page-button-wrapper">
            <div
              className={`tp-page-button ${pathname === "/" ? "active" : ""}`}
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              className={`tp-page-button ${
                pathname === "/card" ? "active" : ""
              }`}
              onClick={() => {
                navigate("/card");
                window.scrollTo(0, 0);
              }}
            >
              TorumPay Card
            </div>
            <div
              className={`tp-page-button ${
                pathname === "/referral" ? "active" : ""
              }`}
              onClick={() => {
                navigate("/referral");
                window.scrollTo(0, 0);
              }}
            >
              Referral Program
            </div>
          </div>
        ) : null}

        {width > 1024 ? (
          <div className="getstarted-lang-wrapper">
            <Button className="filled disabled">Coming Soon</Button>
          </div>
        ) : (
          <div
            className="tp-header-nav-icon Ft-20s-20l-s fw500"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="nav-icon-wrapper">
              <p>{currentPage}</p> {/* Show Current Page Name */}
              <NavIcon />
            </div>

            {/* Navigation Panel with Outside Click Detection */}
            <div
              ref={navRef}
              className={`nav-panel-container ${isOpen ? "show" : "hide"}`}
            >
              {NavigationMobile.filter((panel) => panel.path !== pathname).map(
                (panel, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(panel.path);
                      window.scrollTo(0, 0);
                      setIsOpen(false); // Close menu after clicking
                    }}
                    className={`nav-panel ${
                      pathname === panel.path ? "active" : ""
                    }`}
                  >
                    {panel.page}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
