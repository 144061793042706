export const ROUTES = {
  COMPANY: {
    path: "/company",
    label: "Company",
  },
  CAREER: {
    path: "/career",
    label: "Career",
  },
  ANNOUNCEMENTS: {
    path: "/announcements",
    label: "Announcements",
  },
  FEATURES: {
    path: "/features",
    label: "Features",
  },
  FEES: {
    path: "/fees",
    label: "Fees",
  },
  HELPCENTER: {
    path: "https://www.google.com/",
    label: "Help Center",
    target: "_blank", // Add target property
  },
  TERMSOFUSE: {
    path: "/termsofuse",
    label: "Terms Of Use",
  },
  PRIVACYPOLICY: {
    path: "/privacypolicy",
    label: "Privacy Policy",
  },
  COOKIEPOLICY: {
    path: "/cookiepolicy",
    label: "Cookie Policy",
  },
  SECURITY: {
    path: "/security",
    label: "Security",
  },
  COMPLIANCEINFORMATION: {
    path: "/complianceinformation",
    label: "Compliance Information",
  },
  TRAVELRULE: {
    path: "/travelrule",
    label: "Travel Rule",
  },
};
