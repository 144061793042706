import React, { useRef } from "react";
import { motion } from "framer-motion";
import useInView from "../../hooks/useInView";

const ScrollFade = ({ children, threshold = 0.1, ...props }) => {
  const ref = useRef(null);
  const hasFadedIn = useInView(ref, threshold);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: hasFadedIn ? 1 : 0 }}
      transition={{ duration: 0.8 }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default ScrollFade;
