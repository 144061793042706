import React from 'react';
import './Hero.scss';

import Button from '../../../components/Button/Button';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download-icon.svg';

import HeroImg from '../../../assets/png/hero-img.png';
import HeroMobileImg from '../../../assets/png/hero-mobile-img.png';
import ScLogo from '../../../assets/svg/sc-my-logo.svg';
import HeroVid from '../../../assets/vid/tp-vid-hero.mp4';
import HeroPhone from '../../../assets/png/phone-hero-img.png';
import PhoneElem1 from '../../../assets/png/phone-element-img-1.png';
import PhoneElem2 from '../../../assets/png/phone-element-img-2.png';
import PhoneElem3 from '../../../assets/png/phone-element-img-3.png';
import PhoneElem4 from '../../../assets/png/phone-element-img-4.png';

import useWindowSize from '../../../hooks/useWindowResize';

function Hero() {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const heroDesc = `Trading and spending crypto is as easy as Ringgit.`;

  // Storing heroInfo as an array of JSX elements
  const heroInfo = (
    <>
      We are a provisionally registered digital asset exchange (DAX) operator,
      regulated by the Securities Commission Malaysia.
      <span className="learn-more">
        <a
          href="https://www.sc.com.my/regulation/guidelines/recognizedmarkets/list-of-registered-digital-asset-exchanges"
          target="_blank"
        >
          Learn more
        </a>
      </span>
    </>
  );

  return (
    <div className="tp-main-hero">
      {isMobile ? (
        <div className="tp-main-hero-wrapper">
          <div className="left-content-wrapper">
            <p className="tp-hero-title">
              Your Daily
              <span className="gradient">
                Crypto <span>E-Wallet</span>
              </span>
            </p>

            <div className="tp-hero-mobile-content-wrapper">
              <div className="mobile-image-wrapper">
                <img
                  src={HeroPhone}
                  alt="tp-phone-img"
                  className="mobile-phone-img"
                />
                <img
                  src={PhoneElem1}
                  alt="tp-phone-img"
                  className="mobile-phone-element first"
                />
                <img
                  src={PhoneElem2}
                  alt="tp-phone-img"
                  className="mobile-phone-element second"
                />
                <img
                  src={PhoneElem3}
                  alt="tp-phone-img"
                  className="mobile-phone-element third"
                />
                <img
                  src={PhoneElem4}
                  alt="tp-phone-img"
                  className="mobile-phone-element fourth"
                />
              </div>
              <video
                autoPlay="autoplay"
                playsInline={true}
                loop={true}
                muted={true}
                className="tp-heromobile-vid"
              >
                <source src={HeroVid} type="video/webm" />
                {/* note: webm video fails to play on Safari -> fallback to mp4 */}
                <source src={HeroVid} type="video/mp4" />
              </video>
            </div>

            <p className="tp-hero-desc">{heroDesc}</p>
            {/* <Button className="filled-expand reversed-rr" icon={DownloadIcon}>
              Join Waitlist
            </Button> */}
            <div className="hero-info-wrapper">
              <p className="tp-hero-info">{heroInfo}</p>
              <img src={ScLogo} alt="sc-my-logo" className="sc-my-logo" />
            </div>
          </div>
        </div>
      ) : (
        <div className="tp-main-hero-wrapper">
          <div className="left-content-wrapper">
            <div className="hero-title-desc-wrapper">
              <p className="tp-hero-title">
                Your Daily <br />
                <span className="gradient">
                  Crypto <span>E-Wallet</span>
                </span>
              </p>
              <p className="tp-hero-desc">{heroDesc}</p>
            </div>
            {/* <Button className="filled-expand reversed-rr" icon={DownloadIcon}>
              Join Waitlist
            </Button> */}
            <div className="hero-info-wrapper">
              <p className="tp-hero-info">{heroInfo}</p>
              <img src={ScLogo} alt="sc-my-logo" className="sc-my-logo" />
            </div>
          </div>

          <div className="tp-hero-img-wrapper">
            <div className="phone-section-wrapper">
              <img src={HeroPhone} alt="tp-phone-img" className="phone-img" />
              <img
                src={PhoneElem1}
                alt="tp-phone-img"
                className="phone-element first"
              />
              <img
                src={PhoneElem2}
                alt="tp-phone-img"
                className="phone-element second"
              />
              <img
                src={PhoneElem3}
                alt="tp-phone-img"
                className="phone-element third"
              />
              <img
                src={PhoneElem4}
                alt="tp-phone-img"
                className="phone-element fourth"
              />
            </div>
            <video
              autoPlay="autoplay"
              playsInline={true}
              loop={true}
              muted={true}
              className="tp-hero-vid"
            >
              <source src={HeroVid} type="video/webm" />
              {/* note: webm video fails to play on Safari -> fallback to mp4 */}
              <source src={HeroVid} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero;
