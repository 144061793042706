export const PAGES = {
  TORUMPAYCARD: {
    path: "/card",
    label: "TorumPayCard",
  },
  REFERRAL: {
    path: "/referral",
    label: "Referral",
  },
};
