import React from "react";

import useWindowSize from "../../hooks/useWindowResize";
import Button from "../../components/Button/Button";

import ReferralHeroImg from "../../assets/webp/referral-hero-img.webp";
import ReferralHeroImg2 from "../../assets/webp/referral-hero-img-2.webp";
import ParticipateImg1 from "../../assets/webp/participate-img-1.png";
import ParticipateImg2 from "../../assets/webp/participate-img-2.png";
import ParticipateImg3 from "../../assets/webp/participate-img-3.png";
import JourneyImg from "../../assets/webp/referral-journey-img.png";
import JourneyImgS2 from "../../assets/webp/referral-journey-img-s2.png";
import JourneyImgS3 from "../../assets/webp/referral-journey-img-s3.png";

import ScrollFade from "../../components/Effects/ScrollFade";

import "../../styles/variables.scss";
import "../../styles/global.scss";
import "./Referral.scss";

const Referral = () => {
  const { width } = useWindowSize();

  const Participate = [
    {
      img: <img src={ParticipateImg1} />,
      title: "Apply for a TorumPay Card",
      desc: "Sign up and activate your TorumPay crypto card to unlock referral rewards.",
    },
    {
      img: <img src={ParticipateImg2} />,
      title: "Share Your Invitation Link",
      desc: "Go to ‘Invite Your Friends’ on the app and share your referral link or QR code.",
    },
    {
      img: <img src={ParticipateImg3} />,
      title: "Sit Back, Relax and Get Paid",
      desc: (
        <>
          <span className="fw600">
            Earn up to RM20 instantly and RM7 monthly
          </span>{" "}
          for each successful TorumPay card application.
        </>
      ),
    },
  ];

  const data =
    width > 1024
      ? [
          ["5 invites", "RM50", "RM15", "RM65"],
          ["25 invites", "RM325", "RM105", "RM430"],
          ["50 invites", "RM800", "RM270", "RM1,070"],
          ["100 invites", "RM1,800", "RM620", "RM2,420"],
          ["250 invites", "RM4,800", "RM1,670", "RM6,470"],
        ]
      : [
          ["5 invites", "RM50 + RM15"],
          ["25 invites", "RM325 + RM105"],
          ["50 invites", "RM800 + RM270"],
          ["100 invites", "RM1,800 + RM620"],
          ["250 invites", "RM4,800 + RM1,670"],
        ];

  const labels =
    width > 1024
      ? [
          "Referrals",
          "One-Time Bonus",
          <>
            Recurring Monthly Bonus
            <span className="Ft-14s-14l gray">
              Deposited into your wallet directly
            </span>
          </>,
          <>
            Total Potential Earnings
            <span className="Ft-14s-14l">One-Time + Recurring</span>
          </>,
        ]
      : [
          "Referrals",
          <>
            Total Potential Earnings
            <span className="Ft-14s-14l">One-Time + Recurring</span>
          </>,
        ];

  const Reward = {
    labels,
    data,
  };

  return (
    <div className="tpreferral-main-container">
      <ScrollFade>
        {/*  Start - Hero Section */}
        <div className="tpreferral-hero-container">
          <div className="tpreferral-hero-wrapper max-width">
            <p className="hero-title Fu-120s-120l">refer. earn. repeat.</p>
            <p className="hero-desc Ft-20s-26l">Why pay when you can earn?</p>
            <p className="hero-desc Ft-20s-26l second">
              With TorumPay's referral program, your card should pay you—not the
              other way around.
            </p>
            <Button className="hero-button disabled Ft-18s-18l">
              Coming Soon
            </Button>

            {width > 600 ? (
              <img src={ReferralHeroImg} className="hero-img" />
            ) : (
              <img src={ReferralHeroImg2} className="hero-img" />
            )}
          </div>
        </div>
        {/*  End - Hero Section */}
      </ScrollFade>

      <ScrollFade>
        {/*  Start - Participate Section */}
        <div className="tpreferral-participate-container">
          <div className="tpreferral-participate-wrapper max-width">
            <p className=" title Fu-90s-90l">HOW TO PARTICIPATE</p>
            <div className="tpreferral-participate-content">
              {Participate.map((panel, index) => (
                <div className="tpreferral-participate-panel" key={index}>
                  {panel.img}
                  <p className="title Ft-26s-32l fw700">{panel.title}</p>
                  <p className="desc Ft-20s-26l">{panel.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*  End - Participate Section */}
      </ScrollFade>

      <ScrollFade>
        {/*  Start -Journey Section */}
        <div className="tpreferral-journey-container">
          <div className="tpreferral-journey-wrapper max-width">
            <div className="left-container">
              <p className="title Fu-90s-90l">YOUR REFERRAL JOURNEY</p>
              <p className="desc Ft-20s-26l">
                Progressive yet sustainable. The more people you invite, the
                more you earn. Level up and unlock bigger rewards as more
                invitees become TorumPay pioneers—just like you.
              </p>
            </div>
            <img
              src={
                width > 425
                  ? JourneyImg
                  : width > 375
                  ? JourneyImgS2
                  : JourneyImgS3
              }
            />
          </div>
        </div>
        {/*  END -Journey Section */}
      </ScrollFade>

      <ScrollFade>
        {/*  Start -Rewards Section */}
        <div className="tpreferral-rewards-container">
          <div className="tpreferral-rewards-wrapper max-width">
            <div className="header-section">
              <p className="Fu-90s-90l">REAL MONEY, REAL REWARDS.</p>
              <p className="rewards-desc Ft-20s-26l">
                This isn’t just cashback—it’s real, recurring income.{" "}
                {width > 768 ? <br /> : ""}
                The more you refer, the bigger your monthly earnings grow.
              </p>
              <p className="rewards-desc Ft-20s-26l fw600">
                Refer just 5 friends and never pay for your card again!
              </p>
            </div>
            <div className="chart-color-wrapper">
              <div className="tpreferral-rewards-chart-wrapper">
                {Reward.labels.map((label, index) => (
                  <div className="tpreferral-rewards-panel" key={index}>
                    <div className="slot label Ft-18s-18l">{label}</div>

                    {Reward.data.map((data, dataIndex) => {
                      const specialClass = index === 3 ? "fw700" : "";

                      return (
                        <div
                          className={`slot data Ft-20s-20l ${specialClass}`}
                          key={`${index}-${dataIndex}`}
                        >
                          {data[index]}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/*  End -Rewards Section */}
      </ScrollFade>
    </div>
  );
};

export default Referral;
