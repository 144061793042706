import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/home/Home";
import TorumPayCard from "./pages/torumpaycard/TorumPayCard";
import Referral from "./pages/referral/Referral";
import Company from "./pages/company/Company";
import Career from "./pages/career/Career";
import Announcements from "./pages/announcements/Announcements";
import Features from "./pages/features/Features";
import Fees from "./pages/fees/Fees";
import TermsOfUse from "./pages/termsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import CookiePolicy from "./pages/cookiePolicy/CookiePolicy";
import Security from "./pages/security/Security";
import ComplianceInformation from "./pages/complianceInformation/ComplianceInformation";
import TravelRule from "./pages/travelRule/TravelRule";

import AppLayout from "./components/AppLayout/AppLayout";
import Modal from "./components/Modal/Modal";
import { ROUTES } from "./constants/navigation";
import { PAGES } from "./constants/pages";

import TorumPayLogo from "./assets/svg/torum-pay-logo.svg";
import IntroImg from "./assets/png/outro-cta-img.png";

import "./styles/variables.scss";
import "./App.scss"; // Ensure you include your fallback styles here

const LoadingScreen = ({ slideOut, slideOutv2, fadeOut }) => {
  const [typedText, setTypedText] = useState("");
  const fullText = "Your Gateway to Crypto Payments";

  useEffect(() => {
    let currentIndex = 0;

    const typingEffect = () => {
      setTypedText(fullText.slice(0, currentIndex + 1)); // Slice the string based on the current index
      currentIndex++;

      // Stop when we've typed the full text
      if (currentIndex >= fullText.length) {
        clearInterval(typingInterval); // Clear the interval once typing is done
      }
    };

    const typingInterval = setInterval(typingEffect, 50); // Run the typing effect every 100ms

    return () => clearInterval(typingInterval); // Cleanup interval on component unmount
  }, [fullText]);

  return (
    <div className={`intro-screen ${fadeOut ? "fade-out" : ""}`}>
      <div className={`logo-wrapper  ${slideOut ? "slide-left" : ""}`}>
        <img
          src={TorumPayLogo}
          alt="torum-pay-logo"
          className="torum-pay-logo"
        />
      </div>
      <div className={`intro-text ${slideOutv2 ? "slide-left-more" : ""}`}>
        <p className="Ft-20s-20l fw500">{typedText}</p>
      </div>
    </div>
  );
};

function App() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isUserAgreed, setUserAgreed] = useState(null);

  const [loading, setLoading] = useState(true); // Controls if the loading screen is visible
  const [fadeOut, setFadeOut] = useState(false); // Controls the fade-out effect
  const [slideOut, setSlideOut] = useState(false);
  const [slideOutv2, setSlideOutv2] = useState(false);

  useEffect(() => {
    // Set a timer for sliding out the loading screen
    const slideTimer = setTimeout(() => {
      setSlideOut(true);
    }, 4200); // Trigger the slide after 4 seconds

    // Set a timer for sliding out the loading screen
    const slideTimerv2 = setTimeout(() => {
      setSlideOutv2(true);
    }, 4300); // Trigger the slide after 3.8 seconds

    // Trigger fade out before hiding the loading screen completely
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true);
    }, 4600); // Start fade-out after 4.5 seconds

    // Hide the loading screen completely after fade-out
    const hideTimer = setTimeout(() => {
      setLoading(false);
    }, 5600); // Remove loading screen after 5 seconds

    return () => {
      clearTimeout(slideTimer);
      clearTimeout(slideTimerv2);
      clearTimeout(fadeOutTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  useEffect(() => {
    // Check localStorage on initial load
    const agreement = localStorage.getItem("userAgreed");

    if (agreement === "true") {
      setUserAgreed(true);
      setModalVisible(false);
    } else if (agreement === "false") {
      setUserAgreed(false);
      setModalVisible(false);
    } else {
      // No previous agreement stored, show modal
      setModalVisible(true);
    }
  }, []);

  useEffect(() => {
    // Disable scrollbar when the modal is visible
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isModalVisible]);

  const handleAgree = () => {
    localStorage.setItem("userAgreed", "true");
    setUserAgreed(true);
    setModalVisible(false);
  };

  const handleDisagree = () => {
    localStorage.removeItem("userAgreed"); // Remove the agreement status
    setUserAgreed(false);
    setModalVisible(false);
  };

  if (isUserAgreed === false) {
    return (
      <div className="fallback-container">
        <p className="fallback-title">Access Denied</p>
        <p className="fallback-desc">
          You must agree to the terms to view this website. Please refresh the
          page.
        </p>
      </div>
    );
  }

  return (
    <>
      {loading && (
        <LoadingScreen
          slideOut={slideOut}
          slideOutv2={slideOutv2}
          fadeOut={fadeOut}
        />
      )}
      <Modal
        isVisible={isModalVisible}
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path={PAGES.TORUMPAYCARD.path} element={<TorumPayCard />} />
          <Route path={PAGES.REFERRAL.path} element={<Referral />} />
          {/* <Route path={ROUTES.COMPANY.path} element={<Company />} />
          <Route path={ROUTES.CAREER.path} element={<Career />} />
          <Route path={ROUTES.ANNOUNCEMENTS.path} element={<Announcements />} />
          <Route path={ROUTES.FEATURES.path} element={<Features />} />
          <Route path={ROUTES.FEES.path} element={<Fees />} />
          <Route path={ROUTES.TERMSOFUSE.path} element={<TermsOfUse />} />
          <Route path={ROUTES.PRIVACYPOLICY.path} element={<PrivacyPolicy />} />
          <Route path={ROUTES.COOKIEPOLICY.path} element={<CookiePolicy />} />
          <Route path={ROUTES.SECURITY.path} element={<Security />} /> */}
          <Route
            path={ROUTES.COMPLIANCEINFORMATION.path}
            element={<ComplianceInformation />}
          />
          <Route path={ROUTES.TRAVELRULE.path} element={<TravelRule />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
