import { useState, useCallback, memo, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./GetStarted.scss";

import Button from "../../../components/Button/Button";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/download-icon.svg";
import GetStartedBg from "../../../assets/png/get-started-bg.png";
import GetStartedImg1 from "../../../assets/png/get-started-img-1.png";
import GetStartedImg2 from "../../../assets/png/get-started-img-2.png";
import GetStartedImg3 from "../../../assets/png/get-started-img-3.png";
import GetStartedImg4 from "../../../assets/png/get-started-img-4.png";

import useWindowSize from "../../../hooks/useWindowResize";
import ImagePreloader from "../../../components/ImagePreloader/ImagePreloader";

function GetStarted() {
  const [paymentType, setPaymentType] = useState("Pay");
  const { width } = useWindowSize();
  const isMobile = width <= 480;
  const isOverlap = width <= 992;

  const selectPaymentType = useCallback((type) => {
    setPaymentType(type);
  }, []);

  const paymentTypeData = {
    Pay: {
      img: GetStartedImg1,
      title: (
        <>
          <span className="break-first-title">
            Spend {isMobile ? <br /> : ""} Literally {isMobile ? <br /> : ""}
            Anywhere
          </span>
        </>
      ),
      desc: "Enjoy seamless conversion and spending of your crypto assets with Ringgit anywhere around the world.",
    },
    Trade: {
      img: GetStartedImg3,

      title: (
        <>
          <span className="break-first-title">
            Effortless {isMobile ? <br /> : ""} crypto {isMobile ? <br /> : ""}
            conversion
          </span>
        </>
      ),
      desc: "Experience seamless trading on TorumPay. Easily exchange between cryptocurrencies and Ringgit with ease and security.",
    },
    Stake: {
      img: GetStartedImg4,
      title: (
        <>
          <span className="break-first-title">
            Stake, Earn, and Spend Crypto Seamlessly
          </span>
        </>
      ),
      desc: "Earn up to 9% annual interest by staking your cryptocurrency with TorumPay and get paid every day. Track your rewards in real-time and seamlessly convert them into spending power.",
    },
    Send: {
      img: GetStartedImg2,
      title: (
        <>
          <span className="break-first-title">
            Swift crypto transfers on torumpay
          </span>
        </>
      ),
      desc: "Experience swift and secure cryptocurrency transfers with TorumPay. Send digital assets to your friends instantly, hassle-free.",
    },
  };

  // Preload images using the ImagePreloader component
  const imageSources = Object.values(paymentTypeData).map((data) => data.img);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPaymentType((prevType) => {
        const keys = Object.keys(paymentTypeData);
        const currentIndex = keys.indexOf(prevType);
        const nextIndex = (currentIndex + 1) % keys.length;
        return keys[nextIndex];
      });
    }, 5000); // Auto-switch every 5 seconds

    return () => clearInterval(intervalId);
  }, [paymentTypeData]);

  const MemoizedButton = memo(({ children, ...props }) => (
    <Button {...props}>{children}</Button>
  ));

  const contentVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
  };

  const transitionSettings = {
    duration: 0.3,
    ease: "easeInOut",
  };

  return (
    <div className="tp-main-getstarted">
      {/* Preload images */}
      <ImagePreloader srcList={imageSources} />

      {isOverlap ? (
        <div className="tp-main-getstarted-wrapper ">
          <div className="getstarted-left-content">
            <p className="getstarted-top-title">
              Getting started with TorumPay
            </p>

            <AnimatePresence
              mode="wait"
              initial={false}
              onExitComplete={() => null}
            >
              <motion.div
                key={paymentType}
                variants={contentVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={transitionSettings}
                className="getstarted-left-content-wrapper "
              >
                <p className="getstarted-title">
                  {paymentTypeData[paymentType].title}
                </p>

                <div className="mobile">
                  {paymentTypeData[paymentType].img && (
                    <img
                      src={paymentTypeData[paymentType].img}
                      alt="Selected Payment Type"
                      className="payment-img"
                    />
                  )}
                </div>

                <p className="getstarted-desc">
                  {paymentTypeData[paymentType].desc}
                </p>
              </motion.div>
            </AnimatePresence>

            <div className="button-wrapper">
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Pay")}
                active={paymentType === "Pay"}
              >
                Pay
              </MemoizedButton>
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Trade")}
                active={paymentType === "Trade"}
              >
                Trade
              </MemoizedButton>
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Stake")}
                active={paymentType === "Stake"}
              >
                Stake
              </MemoizedButton>
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Send")}
                active={paymentType === "Send"}
              >
                Send
              </MemoizedButton>

              <img
                className="get-started-bg"
                src={GetStartedBg}
                alt="get-started-bg"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="tp-main-getstarted-wrapper">
          <div className="getstarted-left-content">
            <p className="getstarted-top-title">
              Getting started with TorumPay
            </p>

            <AnimatePresence mode="wait">
              <motion.div
                key={paymentType}
                variants={contentVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={transitionSettings}
                className="getstarted-left-content-wrapper"
              >
                <p className="getstarted-title">
                  {paymentTypeData[paymentType].title}
                </p>
                <p className="getstarted-desc">
                  {paymentTypeData[paymentType].desc}
                </p>
              </motion.div>
            </AnimatePresence>

            <Button className="filled-expand reversed-rr disabled">
              Coming Soon
            </Button>
          </div>

          <div className="getstarted-right-content">
            <img
              className="get-started-bg"
              src={GetStartedBg}
              alt="get-started-bg"
            />

            <AnimatePresence mode="wait">
              <motion.div
                key={paymentType}
                variants={contentVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={transitionSettings}
              >
                {paymentTypeData[paymentType].img && (
                  <img
                    src={paymentTypeData[paymentType].img}
                    alt="Selected Payment Type"
                    className="payment-img"
                  />
                )}
              </motion.div>
            </AnimatePresence>

            <div className="button-wrapper">
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Pay")}
                active={paymentType === "Pay"}
              >
                Pay
              </MemoizedButton>
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Trade")}
                active={paymentType === "Trade"}
              >
                Trade
              </MemoizedButton>
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Stake")}
                active={paymentType === "Stake"}
              >
                Stake
              </MemoizedButton>
              <MemoizedButton
                className="outlined-dark fixed-width"
                onClick={() => selectPaymentType("Send")}
                active={paymentType === "Send"}
              >
                Send
              </MemoizedButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GetStarted;
