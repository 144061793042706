import React from "react";
import Accordion from "../../../components/Accordion/Accordion";
import Button from "../../../components/Button/Button";

import { ReactComponent as TopRightArrow } from "../../../assets/svg/top-right-arrow.svg";

import "./Faq.scss";

const faqs = [
  {
    question: "What is TorumPay?",
    answer:
      "TorumPay is an emerging mobile application in Malaysia that functions as a digital asset (cryptocurrency) exchange. It offers a range of services including crypto trading and Ringgit payments, all within a secure and regulated environment.",
  },
  {
    question: "Is TorumPay a licensed entity?",
    answer: (
      <>
        Yes, TorumPay is a licensed Digital Asset Exchange (DAX) operator{" "}
        <a
          href="https://www.sc.com.my/regulation/guidelines/recognizedmarkets/list-of-registered-digital-asset-exchanges"
          target="_blank"
        >
          regulated by the Securities Commission Malaysia
        </a>
        .
      </>
    ),
  },
  {
    question: "Do I need to complete KYC to use TorumPay?",
    answer:
      "Yes, completing the Know Your Customer (KYC) process is mandatory to access all features involving financial transactions in both Ringgit and cryptocurrencies. This requirement is essential for your protection as an investor and to ensure compliance with regulatory standards.",
  },
  {
    question: "What is the TorumPay card?",
    answer:
      "The TorumPay card allows you to spend your Ringgit at merchants across Malaysia and worldwide upon conversion from cryptocurrencies on the TorumPay app. Upon successful application and subscription, you can enjoy seamless virtual card contactless payments, DuitNow QR payments, and will also receive a complimentary physical card and exclusive gift box delivered to your doorstep.",
  },
  {
    question: "Do I need to convert my crypto assets into MYR?",
    answer:
      "Yes, if you wish to withdraw funds to your bank account or spend them at merchants, you must first convert your crypto assets into Ringgit.",
  },
  {
    question: "Is there any requirement to apply for a TorumPay card?",
    answer:
      "To apply for a TorumPay card, you must first complete the KYC procedure on the TorumPay application. Additional details regarding the card application process will be available soon.",
  },
];

const Faq = () => {
  return (
    <div className="tp-main-faq">
      <div className="tp-main-faq-wrapper">
        <div className="faq-header">
          <p className="faq-title">Frequently Asked Questions</p>
          <p className="faq-desc">
            We understand that you might have questions in mind, and we’ve got
            you covered.
          </p>
        </div>

        <div className="tp-faq-grid">
          <Accordion faqs={faqs.slice(0, Math.ceil(faqs.length / 2))} />
          <Accordion faqs={faqs.slice(Math.ceil(faqs.length / 2))} />
        </div>

        {/* <Button className="empty reversed-rr link" icon={TopRightArrow}>
          For more FAQs
        </Button> */}
      </div>
    </div>
  );
};

export default Faq;
