import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import CardVid from "../../../assets/vid/card-vid.mp4";
import "./CardInfo.scss";

function CardInfo() {
  const navigateTo = useNavigate();
  const videoRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && videoRef.current) {
          videoRef.current.currentTime = 0; // Reset video time
          videoRef.current.play(); // Play video
        }
      },
      { threshold: 0.35 } // Trigger when 50% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="tp-cardinfo-main-container" ref={sectionRef}>
      <div className="tp-cardinfo-top">
        <p className="section-title">BE BOLD, BE DIFFERENT</p>
        <div className="desc-wrapper">
          <p className="section-first-desc">One Card. Infinite Power.</p>
          <p className="section-second-desc">
            Designed for pioneers, crafted for the future.
          </p>
        </div>
        <Button
          className="section-button hero-button"
          onClick={() => {
            navigateTo("/card");
            window.scrollTo(0, 0);
          }}
        >
          Learn more
        </Button>
      </div>

      <video
        ref={videoRef}
        playsInline
        loop={false}
        muted
        className="tp-heromobile-vid"
      >
        <source src={CardVid} type="video/webm" />
        <source src={CardVid} type="video/mp4" />
      </video>
    </div>
  );
}

export default CardInfo;
