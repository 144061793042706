// src/pages/Home/Home.js
import React, { useRef, useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import "./Home.scss";
import Hero from "./Hero/Hero";
import CardInfo from "./CardInfo/CardInfo";
import GetStarted from "./GetStarted/GetStarted";
import Marquee from "./Marquee/Marquee";
import ProductSpotlight from "./ProductSpotlight/ProductSpotlight";
import ProductUsecase from "./ProductUsecase/ProductUsecase";
import OutroCta from "./OutroCta/OutroCta";
import Faq from "./FAQ/Faq";

import ScrollFade from "../../components/Effects/ScrollFade";

const Home = () => {
  const sectionRefs = useOutletContext();

  return (
    <>
      <div className="tp-main-page">
        <ScrollFade>
          <Hero />
        </ScrollFade>
        <ScrollFade>
          <div ref={sectionRefs.CardInfo}>
            <CardInfo />
          </div>
        </ScrollFade>
        <ScrollFade>
          <div ref={sectionRefs.GetStarted}>
            <GetStarted />
          </div>
        </ScrollFade>
        <ScrollFade>
          <Marquee />
        </ScrollFade>
        <ScrollFade>
          <div ref={sectionRefs.Features}>
            <ProductSpotlight />
          </div>
        </ScrollFade>
        <ScrollFade>
          <ProductUsecase />
        </ScrollFade>
        <ScrollFade>
          <div ref={sectionRefs.Faq}>
            <Faq />
          </div>
        </ScrollFade>
        {/* <ScrollFade>
        <div ref={sectionRefs.OutroCta}>
          <OutroCta />
        </div>
      </ScrollFade> */}
      </div>
    </>
  );
};

export default Home;
