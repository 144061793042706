// src/components/AppLayout/AppLayout.js
import React, { useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../AppFooter/AppFooter";

const AppLayout = () => {
  const location = useLocation();

  const sectionRefs = {
    CardInfo: useRef(null),
    Features: useRef(null),
    GetStarted: useRef(null),
    Faq: useRef(null),
  };

  const scrollToSection = (section) => {
    sectionRefs[section]?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <AppHeader
        scrollToSection={location.pathname === "/" ? scrollToSection : null}
      />
      <main className="main">
        {location.pathname === "/" ? (
          <Outlet context={sectionRefs} />
        ) : (
          <Outlet />
        )}
      </main>
      <AppFooter />
    </>
  );
};

export default AppLayout;
