import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ROUTES } from "../../constants/navigation";
import Button from "../Button/Button";

import TorumPayLogo from "../../assets/svg/torum-pay-logo.svg";

import "./AppFooter.scss";

function CustomAlert({ message, onClose }) {
  return (
    <div className="custom-alert">
      <p>{message}</p>
      <button onClick={onClose}>Close</button>
    </div>
  );
}

function AppFooter() {
  const [email, setEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSignUp = () => {
    if (email) {
      console.log("Sign up with email:", email);
      setEmail("");
      setAlertMessage("Thank you for signing up!");
    } else {
      setAlertMessage("Please enter a valid email address.");
    }
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const CurrentYear = () => {
    const currentYear = new Date().getFullYear();

    return currentYear;
  };

  return (
    <div className="tp-main-app-footer">
      <div className="tp-main-app-footer-wrapper">
        <div className="app-footer-top">
          {/* <div className="footer-header">
            <p className="footer-title">Sign up for email updates</p>
            <p className="footer-desc">
              Keep up with the latest TorumPay news and be part of our
              community!
            </p>
          </div>

          <input
            type="email"
            value={email}
            onChange={handleInputChange}
            placeholder="Enter your email"
          />

          <Button className="filled-expand" onClick={handleSignUp}>
            Sign Up
          </Button>

          {showAlert && (
            <CustomAlert message={alertMessage} onClose={closeAlert} />
          )} */}

          <img
            className="torum-pay-logo"
            src={TorumPayLogo}
            alt="torum-pay-logo"
          />

          {/* <div className="app-footer-sub-links-wrapper">
            <div className="app-footer-sub-links about">
              <div>About</div>
              {Object.keys(ROUTES)
                .slice(0, 3)
                .map((key) => (
                  <div key={key} className="sub-links">
                    <NavLink to={ROUTES[key].path}>{ROUTES[key].label}</NavLink>
                  </div>
                ))}
            </div>

            <div className="app-footer-sub-links resources">
              <div>Resources</div>
              {Object.keys(ROUTES)
                .slice(3, 6)
                .map((key) => {
                  const route = ROUTES[key];
                  return (
                    <div key={key} className="sub-links">
                      {route.target === "_blank" ? (
                        <a
                          href={route.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {route.label}
                        </a>
                      ) : (
                        <NavLink to={route.path}>{route.label}</NavLink>
                      )}
                    </div>
                  );
                })}
            </div>

            <div className="app-footer-sub-links legal">
              <div>Resources</div>
              {Object.keys(ROUTES)
                .slice(6, 9)
                .map((key) => (
                  <div key={key} className="sub-links">
                    <NavLink to={ROUTES[key].path}>{ROUTES[key].label}</NavLink>
                  </div>
                ))}
            </div>

            <div className="app-footer-sub-links compliance">
              <div>Compliance</div>
              {Object.keys(ROUTES)
                .slice(9, 12)
                .map((key) => (
                  <div key={key} className="sub-links">
                    <NavLink to={ROUTES[key].path}>{ROUTES[key].label}</NavLink>
                  </div>
                ))}
            </div>
          </div> */}
        </div>

        <div className="app-footer-bottom">
          <p className="app-footer-disclaimer">
            Disclaimer: TorumPay (Torum International Sdn Bhd 202201042743
            [1488440-A]) is a regulated exchange licensed by the Securities
            Commission of Malaysia, with trading of digital assets using MYR
            enabled by our Digital Asset Exchange (DAX) license.
          </p>
          <p className="website-copyright">
            © 2022 - <CurrentYear /> Torum International Sdn. Bhd. All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AppFooter;
