import { useEffect } from "react";

function ImagePreloader({ srcList }) {
  useEffect(() => {
    srcList.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, [srcList]);

  return null; // This component doesn't render anything in the DOM
}

export default ImagePreloader;
