import React from "react";
import Marquee from "react-fast-marquee";
import "./Marquee.scss";
import CarouselImg from "../../../assets/png/carouseltest.png";
import BtcIcon from "../../../assets/png/btc-icon.png";
import EthIcon from "../../../assets/png/eth-icon.png";
import AvaxIcon from "../../../assets/png/avax-icon.png";
import LinkIcon from "../../../assets/png/chainlink-icon.png";
import MaticIcon from "../../../assets/png/polygon-icon.png";
import XrpIcon from "../../../assets/png/xrp-icon.png";

import useWindowSize from "../../../hooks/useWindowResize";

const cryptoToken = [
  { img: BtcIcon, name: "Bitcoin", symbol: "$btc" },
  { img: EthIcon, name: "Ethereum", symbol: "$eth" },
  { img: AvaxIcon, name: "Avalanche", symbol: "$avax" },
  { img: LinkIcon, name: "Chainlink", symbol: "$link" },
  { img: MaticIcon, name: "Polygon", symbol: "$matic" },
  { img: XrpIcon, name: "XRP", symbol: "$xrp" },
];

// Duplicate items to ensure a seamless loop
const duplicatedItems = [
  ...cryptoToken,
  ...cryptoToken,
  ...cryptoToken,
  ...cryptoToken,
  ...cryptoToken,
  ...cryptoToken,
];

function MarqueeComponent() {
  const { width } = useWindowSize();
  const isMobile = width <= 480;

  return (
    <>
      <p className="tp-main-crypto-tag-title">
        Access 17+ Cryptocurrencies for transactions all around the world.
      </p>
      <div className="tp-main-crypto-tag">
        {isMobile ? (
          <div className="mobile-crypto-tag-wrapper">
            <Marquee
              speed={50} // Adjust speed here
              gradient={false} // Remove gradient effect
              pauseOnHover={false}
            >
              {duplicatedItems.map((cryptoToken, index) => (
                <div className="gap">
                  <div className="tp-crypto-tag-wrapper" key={index}>
                    <img
                      src={cryptoToken.img}
                      alt={`${cryptoToken.name} logo`}
                    />
                    <div className="crypto-tag-details">
                      <p className="crypto-tag-title">{cryptoToken.name}</p>
                      <p className="crypto-tag-sign">{cryptoToken.symbol}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Marquee>
            <Marquee
              speed={50} // Adjust speed here
              gradient={false} // Remove gradient effect
              pauseOnHover={false}
              direction="right"
            >
              {duplicatedItems.map((cryptoToken, index) => (
                <div className="gap">
                  <div className="tp-crypto-tag-wrapper" key={index}>
                    <img
                      src={cryptoToken.img}
                      alt={`${cryptoToken.name} logo`}
                    />
                    <div className="crypto-tag-details">
                      <p className="crypto-tag-title">{cryptoToken.name}</p>
                      <p className="crypto-tag-sign">{cryptoToken.symbol}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Marquee>{" "}
          </div>
        ) : (
          <Marquee
            speed={50} // Adjust speed here
            gradient={false} // Remove gradient effect
            pauseOnHover={false}
          >
            {duplicatedItems.map((cryptoToken, index) => (
              <div className="gap">
                <div className="tp-crypto-tag-wrapper" key={index}>
                  <img src={cryptoToken.img} alt={`${cryptoToken.name} logo`} />
                  <div className="crypto-tag-details">
                    <p className="crypto-tag-title">{cryptoToken.name}</p>
                    <p className="crypto-tag-sign">{cryptoToken.symbol}</p>
                  </div>
                </div>
              </div>
            ))}
          </Marquee>
        )}
      </div>
    </>
  );
}

export default MarqueeComponent;
