import React, { useState, useEffect, useRef } from "react";

import TorumPayIcon from "../../../assets/webp/cp-torumpay-icon.webp";
import RedotPayIcon from "../../../assets/webp/cp-redotpay-icon.webp";
import BybitCardIcon from "../../../assets/webp/cp-bybit-icon.webp";
import PlusIcon from "../../../assets/svg/plus-icon.svg";
import MinusIcon from "../../../assets/svg/minus-icon.svg";

import useWindowSize from "../../../hooks/useWindowResize";

import "../../../styles/global.scss";
import "../../../styles/mixins.scss";
import "../../../styles/variables.scss";
import "./CanSave.scss";

const CanSave = () => {
  const { width } = useWindowSize();
  const [monthlySpending, setMonthlySpending] = useState(3000);
  const [isOpen, setIsOpen] = useState([0]);
  const panelRefs = useRef({});

  const toggleAccordion = (clickedIndex) => {
    setIsOpen(
      (prevOpen) =>
        prevOpen.includes(clickedIndex)
          ? prevOpen.filter((index) => index !== clickedIndex) // Close if already open
          : [...prevOpen, clickedIndex] // Open if not already open
    );
  };

  // Format numbers with commas (e.g., "1,234.00")
  const formatNumber = (num) => {
    return Number(num).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const Providers = [
    {
      logo: "",
      name: "",
      paymentAmount: (
        <>
          Payment Amount <span className="gray Ft-14s-14l">in Ringgit</span>
        </>
      ),
      rate: "FX Rate Markup",
      transactionFee: (
        <>
          Transaction Fee{" "}
          <span className="gray Ft-14s-14l">Crypto-to-Fiat Off-Ramp</span>
        </>
      ),
      fee: "Total Fee",
      totalSpend: "Total Spending Cost",
    },
    {
      logo: <img src={TorumPayIcon} />,
      name: "TorumPay",
      paymentAmount: `RM${formatNumber(monthlySpending)}`,
      rate: "0%",
      transactionFee: <>1%</>,
      fee: `RM${formatNumber(monthlySpending * 0.01)}`,
      totalSpend: `RM${formatNumber(monthlySpending * 1.01)}`,
    },
    {
      logo: <img src={RedotPayIcon} />,
      name: "RedotPay",
      paymentAmount: `RM${formatNumber(monthlySpending)}`,
      rate: "1.2%",
      transactionFee: <>1%</>,
      fee: `RM${formatNumber(monthlySpending * 0.022)}`,
      totalSpend: `RM${formatNumber(monthlySpending * 1.022)}`,
    },
    {
      logo: <img src={BybitCardIcon} />,
      name: "Bybit Card",
      paymentAmount: `RM${formatNumber(monthlySpending)}`,
      rate: "1%",
      transactionFee: <>0.9%</>,
      fee: `RM${formatNumber(monthlySpending * 0.019)}`,
      totalSpend: `RM${formatNumber(monthlySpending * 1.019)}`,
    },
  ];

  return (
    <div className="tpcard-cansave-container">
      <p className="section-title Fu-90s-90l">HOW MUCH YOU CAN SAVE</p>
      <p className="section-desc Ft-20s-26l">
        Compare and calculate below to see how much you save with fair and
        regulated Crypto-to-Ringgit conversion.
      </p>
      <div className="tpcard-cansave-wrapper max-width">
        <div className="tpcard-cansave-calculator">
          <div className="slider-container">
            <p className="est-monthly Ft-20s-20l fw500">
              Est. Monthly Spending
            </p>
            <input
              type="range"
              min="1000"
              max="20000"
              step="100"
              value={monthlySpending}
              onChange={(e) => setMonthlySpending(Number(e.target.value))}
              className="slider "
            />
            <p className="slider-value Ft-24s-24l fw600">
              RM {formatNumber(monthlySpending)}
            </p>
          </div>
        </div>

        <div className="tpcard-cansave-chart-header">
          <p className="Ft-26s-32l fw600">
            Let’s break down the actual costs for a
          </p>
          <p className="Ft-36s-38l fw800">
            RM{formatNumber(monthlySpending)} /mo. payment transaction.
          </p>
        </div>
        {width > 768 ? (
          <div className="tpcard-cansave-chart-container">
            {Providers.map((panel, index) => (
              <div
                className="tpcard-cansave-chart-panel Ft-20s-28l"
                key={index}
              >
                <div className="slot header-row">
                  {panel.logo}
                  {panel.name}
                </div>
                <div className="slot">{panel.paymentAmount}</div>
                <div className="slot">{panel.rate}</div>
                <div className="slot">{panel.transactionFee}</div>
                <div className="slot">{panel.fee}</div>
                <div className="slot fw600">{panel.totalSpend}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="tpcard-cansive-mobile-container">
            {Providers.slice(1).map((panel, index) => (
              <div
                className="tpcard-cansive-mobile-wrapper"
                onClick={() => toggleAccordion(index)}
                key={index}
              >
                <div className="tpcard-cansive-mobile-header">
                  <div className="header-left Ft-18s-18l fw500">
                    {panel.logo}
                    {panel.name}
                  </div>
                  <img
                    src={isOpen.includes(index) ? MinusIcon : PlusIcon}
                    alt="toggle icon"
                  />
                </div>

                <div
                  ref={(el) => (panelRefs.current[index] = el)}
                  className="detail-panel-container"
                  style={{
                    maxHeight: isOpen.includes(index)
                      ? `${panelRefs.current[index]?.scrollHeight}px`
                      : "0px",
                    opacity: isOpen.includes(index) ? "1" : "0",
                    transition:
                      "max-height 0.4s ease-in-out, opacity 0.1s ease-in-out",
                  }}
                >
                  {Object.entries(Providers[0])
                    .slice(2, width < 768 ? -1 : undefined)
                    .map(([key, headerValue], i) => (
                      <div key={i} className="detail-panel">
                        <div className="label Ft-16s-16l">{headerValue}</div>
                        <div className="value Ft-18s-18l">{panel[key]}</div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="tpcard-cansave-chart-footer Ft-24s-30l fw600">
          <p>
            <span>Save up to </span>
            <span className="fw800 purple">
              RM{formatNumber(monthlySpending * 0.144)}
            </span>
            <span className="fw800 "> per year</span> with TorumPay just by
            spending like you normally would.
          </p>
        </div>
      </div>

      <p className="bottom-text Ft-16s-24l">
        This applies when you perform domestic payments.
      </p>
    </div>
  );
};

export default CanSave;
