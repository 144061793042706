import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import useWindowSize from "../../hooks/useWindowResize";

import TorumPayCardImg from "../../assets/webp/torumpaycard-hero-img.png";
import TwoEthCoinImg from "../../assets/webp/two-ethcoin-img.webp";
import WhatsIncludedImg1 from "../../assets/webp/whatsincluded-img-1.webp";
import WhatsIncludedImg2 from "../../assets/webp/whatsincluded-img-2.webp";
import WhatsIncludedImg3 from "../../assets/webp/whatsincluded-img-3.webp";
import CardBenefitsImg1 from "../../assets/webp/cardbenefits-img-1.png";
import CardBenefitsImg2 from "../../assets/webp/cardbenefits-img-2.png";
import CardBenefitsImg3 from "../../assets/webp/cardbenefits-img-3.png";
import ReferAndEarnImg from "../../assets/webp/refer-and-earn-img.webp";
import AlwaysControlImg1 from "../../assets/webp/instant-card-control.webp";
import AlwaysControlImg2 from "../../assets/webp/customizable-security-settings.webp";
import AlwaysControlImg3 from "../../assets/webp/auto-reload-for-effortless-spending.webp";

import Button from "../../components/Button/Button";

import CompareTheDifference from "./CompareTheDifference/CompareTheDifference";
import CanSave from "./CanSave/CanSave";

import ScrollFade from "../../components/Effects/ScrollFade";

import "./TorumPayCard.scss";
import "../../styles/variables.scss";
import "../../styles/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TorumPayCard = () => {
  const { width } = useWindowSize();
  const navigateTo = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: width > 992 ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    centerMode: true,
    centerPadding: "20px",
    initialSlide: 0,
  };

  const HeroBottomInfoPanel = [
    {
      img: <img src={TwoEthCoinImg} className="twoethcoinimg" />,
      plan: "Staking Plan",
      title: "GET IT FREE",
      tag: "BY STAKING 1 ETH / 10 SOL",
      desc: "Receive your card for free and enjoy its benefits upon staking your ETH on TorumPay, indefinitely. Activation fee and delivery fee are waived.",
    },
    {
      plan: "Monthly Plan",
      title: (
        <>
          RM14.99<span className="gray Fu-40s-40l">/MO.</span>
        </>
      ),
      tag: "CANCEL ANYTIME",
      desc: (
        <>
          Your 1st month will be billed at RM49.99 <br /> (includes a one-time
          RM35.00 card activation fee and delivery fee.)
        </>
      ),
    },
  ];

  const WhatsIncluded = [
    {
      img: <img src={WhatsIncludedImg1} />,
      title: <>Instant Virtual & Free Physical Card</>,
      desc: "Get immediate access to your virtual card and receive a complimentary physical crypto card upon successful application.",
    },
    {
      img: <img src={WhatsIncludedImg2} />,
      title: <>SECURE Doorstep Delivery</>,
      desc: "Enjoy secure and convenient delivery by our trusted experts, right to your doorsteps.",
    },
    {
      img: <img src={WhatsIncludedImg3} />,
      title: <>Premium Gift Box Experience</>,
      desc: "Celebrate your first-ever officially recognized crypto card in Malaysia with an exclusive gift box.",
    },
  ];

  const CardBenefits = [
    {
      img: <img src={CardBenefitsImg1} />,
      title: <>Spend Anywhere</>,
      desc: "Convert your cryptocurrencies into Ringgit and start spending at 100m+ worldwide merchants and 1m+ DuitNow merchants.",
    },
    {
      img: <img src={CardBenefitsImg2} />,
      title: <>Versatile Payment Options</>,
      desc: "Make payments your way, whether it’s contactless or via DuitNow QR, all through the TorumPay mobile app.",
    },
    {
      img: <img src={CardBenefitsImg3} />,
      title: <>Exclusive Trading Offers</>,
      desc: "Enjoy a lifetime 50% discount on trading fees following your successful card application and subscription.",
    },
  ];

  const AlwaysControl = [
    {
      title: "Instant Card Control",
      img: <img src={AlwaysControlImg1} />,
      desc: "Freeze and unfreeze your card instantly for added security and peace of mind.",
    },
    {
      title: "Customizable Security Settings",
      img: <img src={AlwaysControlImg2} />,
      desc: "Set spending limits, enable or disable online transactions, and tailor your card settings to match your needs.",
    },
    {
      title: "Auto-Reload for Effortless Spending",
      img: <img src={AlwaysControlImg3} />,
      desc: "Never run out of balance. Enable auto-reload for uninterrupted transactions.",
    },
  ];

  return (
    <div className="tpcard-main-container">
      <ScrollFade>
        <div className="tpcard-hero">
          <div className="tpcard-hero-wrapper max-width">
            <div className="tpcard-hero-left">
              <p className="tpcard-hero-title Fu-120s-120l ">
                Your Ultimate Crypto Card
              </p>
              <p className="tpcard-hero-desc  Ft-20s-26l">
                <span className="fw600">Your all-in-one crypto card. </span>{" "}
                <br /> Spend seamlessly, stake for benefits, and experience
                financial freedom—fully regulated and designed for pioneers like
                you.
              </p>
              <Button className="hero-button disabled Ft-18s-18l">
                Coming Soon
              </Button>
            </div>
            <img src={TorumPayCardImg} className="tpcard-hero-img max-width" />
          </div>

          {/* Double Info Panel under Hero */}
          <div className="tpcard-info-panel-hero-wrapper max-width">
            {HeroBottomInfoPanel.map((panel, index) => (
              <div className="tpcard-info-panel" key={index}>
                {panel.img}
                <p className="plan Ft-22s-28l fw500">{panel.plan}</p>
                <div className="title Fu-64s-64l">{panel.title}</div>
                <div
                  className={`tag Ft-20s-20l fw600 ${
                    index === 1 ? "second-tag" : ""
                  }`}
                >
                  {panel.tag}
                </div>
                <p className="desc Ft-20s-26l">{panel.desc}</p>
              </div>
            ))}
          </div>
          {/* Double Info Panel under Hero */}
        </div>
      </ScrollFade>

      <div className="gradient">
        <ScrollFade>
          {/* Start - What's Included Section */}
          <div className="tpcard-whatsincluded-wrapper max-width">
            <p className="whatsincluded-section-title Fu-90s-90l">
              WHAT’S INCLUDED
            </p>
            {WhatsIncluded.map((panel, index) => (
              <div className="tpcard-whatsincluded-panel" key={index}>
                <div className="tpcard-whatsincluded-img">{panel.img}</div>
                <div className="tpcard-whatsincluded-info">
                  <p className="tpcard-whatsincluded-title Fu-70s-70l">
                    {panel.title}
                  </p>
                  <p className="tpcard-whatsincluded-desc Ft-20s-28l">
                    {panel.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/*End - What's Included Section */}
        </ScrollFade>

        <ScrollFade>
          {/* Start - CARD BENEFITS Section */}
          <div className="tpcard-cardbenefits-wrapper max-width">
            <p className="cardbenefits-section-title Fu-90s-90l">
              CARD BENEFITS
            </p>
            {CardBenefits.map((panel, index) => (
              <div className="tpcard-cardbenefits-panel" key={index}>
                <div className="tpcard-cardbenefits-img">{panel.img}</div>
                <div className="tpcard-cardbenefits-info">
                  <p className="tpcard-cardbenefits-title Ft-18s-18l fw600">
                    {panel.title}
                  </p>
                  <p className="tpcard-cardbenefits-desc Ft-32s-38l fw600">
                    {panel.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* End - CARD BENEFITS Section */}
        </ScrollFade>

        <ScrollFade>
          {/* Start - COMPARE THE DIFFERENCE Section */}
          <CompareTheDifference />
          {/* End - COMPARE THE DIFFERENCE Section */}
        </ScrollFade>
      </div>

      <ScrollFade>
        {/* Start - HOW MUCH YOU CAN SAVE Section */}
        <CanSave />
        {/* End - HOW MUCH YOU CAN SAVE Section */}
      </ScrollFade>

      <ScrollFade>
        {/* Start - REFER AND EARN Section */}
        <div className="tpcard-referandearn-container">
          <div className="tpcard-referandearn-wrapper max-width">
            <div className="tpcard-referandearn-left">
              <p className="Fu-90s-90l">REFER AND EARN</p>
              <p className="Ft-20s-26l">
                Let your referrals pay for your TorumPay card—invite just 5
                friends and cover your monthly fees effortlessly.
              </p>
              <p className="Ft-20s-26l">
                Even better, keep referring and turn it into a juicy passive
                income!.
              </p>
              <div
                className="tpcard-referandearn-button Ft-18s-18l fw600"
                onClick={() => {
                  navigateTo("/referral");
                  window.scrollTo(0, 0);
                }}
              >
                Learn more
              </div>
            </div>
            <img src={ReferAndEarnImg} />
          </div>
        </div>
        {/* End - REFER AND EARN Section */}
      </ScrollFade>

      <ScrollFade>
        {/* Start -  YOU’RE always in Control Section */}
        <div className="tpcard-alwayscontrol-container">
          <p className="section-title Fu-90s-90l">YOU’RE ALWAYS IN CONTROL</p>
          <p className="section-desc Ft-20s-26l">
            Take charge of your TorumPay card with powerful security and
            convenience features, ensuring seamless and secure spending at all
            times.
          </p>

          <div className="tpcard-alwayscontrol-wrapper">
            {width > 1024 ? (
              // Desktop View: Normal Panels (NO Slider)
              AlwaysControl.map((panel, index) => (
                <div className="tpcard-alwayscontrol-panel" key={index}>
                  <p className="panel-title Ft-26s-32l fw700">{panel.title}</p>
                  {panel.img}
                  <p className="panel-desc Ft-20s-26l">{panel.desc}</p>
                </div>
              ))
            ) : (
              // Mobile View: Use Slider for Swiping
              <div className="slider-container">
                <Slider {...settings}>
                  {AlwaysControl.map((panel, index) => (
                    <div className="tpcard-alwayscontrol-slide" key={index}>
                      <p className="panel-title Ft-26s-32l fw700">
                        {panel.title}
                      </p>
                      {panel.img}
                      <p className="panel-desc Ft-20s-26l">{panel.desc}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
        {/* End - YOU’RE always in Control Section */}
      </ScrollFade>
    </div>
  );
};

export default TorumPayCard;
